import styled from "styled-components"

import { PageHelmet } from "src/ui/Helmet/PageHelmet"

/** @deprecated Don't use this directly; use MainView instead */
export function MainContentBox({
  helmet,
  banner = null,
  children,
  ...rest
}: {
  helmet?: string
  banner?: React.ReactNode
  children: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  rest?: any
}) {
  return (
    <>
      <PageHelmet helmet={helmet} />
      {banner}
      <Box {...rest}>{children}</Box>
    </>
  )
}

const Box = styled.div``
